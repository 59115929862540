import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=f6c30ade&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&id=f6c30ade&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6c30ade",
  null
  
)


    import installComponents from "!C:/Projects/rentmagic.website.vue.limburg/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow})
    

export default component.exports